<template>
    <Layout>
      <el-form
       :rules="rules" ref="formData"
        :model="formData"
        label-width="100px"
        style="max-width: 800px; margin: 50px auto;"
      >
      <el-row>
      <el-col :span="12">
      <el-form-item class="bottom" label="证件照" prop="avatar" :rules="[{ required: true, validator: validateAvatar, trigger: 'blur' }]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            accept=".png,.jpg,.jpeg"
        >
            <img v-if="formData.avatar" :src="formData.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        </el-form-item>
        <div style="color: black;font-size: 12px;margin-left:6rem;" class="">*请上传免冠正面登记照，大小不超过2m</div>
        <el-form-item label="真实姓名" :required="true" prop="realname" :rules="[{ required: true, validator: validateRealname, trigger: 'blur' }]">
          <el-input v-model="formData.realname" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :required="true" prop="phone" :rules="[{validator: validatePhone, trigger: 'blur' }]">
        <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
    </el-form-item>
        <el-form-item label="性别" :required="true" prop="sex" :rules="[{validator: validateSex, trigger: 'blur' }]">
        <el-select v-model="formData.sex" placeholder="请选择性别">
          <el-option value="男">男</el-option>
          <el-option value="女">女</el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="学历" prop="degree" :rules="[{validator: validateEdu, trigger: 'blur' }]">
    <el-select v-model="formData.degree" placeholder="请选择学历" >
      <el-option
        v-for="item in educationOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="学历是否全日制" :required="true" prop="is_fulltime">
        <el-select v-model="formData.is_fulltime" placeholder="请选择是否全日制">
          <el-option value="是">是</el-option>
          <el-option value="否">否</el-option>
        </el-select>
    </el-form-item>
        <el-form-item label="出生年月" :required="true" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker v-model="formData.birthday" type="date" placeholder="选择日期">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="专业类型" :required="true" prop="subject_type" :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
        <el-select v-model="formData.subject_type" placeholder="请选择专业">
          <el-option
        v-for="major in majorList"
        :key="major.value"
        :label="major.label"
        :value="major.value"
      ></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="专业" :required="true" prop="subject" :rules="[{validator: validatesubject, trigger: 'blur' }]">
      <el-input v-model="formData.subject"></el-input>
    </el-form-item>
    <el-form-item label="毕业学校" :required="true" prop="subject" :rules="[{validator: validateschool, trigger: 'blur' }]">
        <el-input v-model="formData.school" placeholder="请输入毕业学校"></el-input>
    </el-form-item>
      </el-col>
      <el-col :span="12" class="elcol2">
       <el-form-item label="身份证正面" :required="true" prop="idcard" :rules="[{validator: validateidcard2, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestidcard"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.idcard">
          <img :src="formData.idcard" class="avatar2"/>
        </template>
        <template v-else>
          <img :src="defaultUrl" class="avatar2" />
        </template>
        </el-upload>
       </el-form-item>
       <el-form-item label="身份证反面" :required="true" prop="idcard2" :rules="[{validator: validateidcard2, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestidcard2"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.idcard2">
          <img :src="formData.idcard2" class="avatar2"/>
        </template>
        <template v-else>
          <div class="avatar-uploader-wrapper">
              <div class="avatar-uploader2">
                <img :src="defaultUrl" class="avatar2"/>
              </div>
          </div>
        </template>
        </el-upload>
       </el-form-item>
       <el-form-item label="毕业证书" :required="true" prop="grad" :rules="[{validator: validategrad, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestgrad"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.grad">
          <img :src="formData.grad" class="avatar2"/>
        </template>
        <template v-else>
          <div class="avatar-uploader-wrapper">
              <div class="avatar-uploader2">
                <img :src="defaultUrl" class="avatar2"/>
              </div>
          </div>
        </template>
        </el-upload>
       </el-form-item> 
    <template v-for="(field, index) in fieldlist" :key="index">
  <el-form-item :label="field.fieldname" :required="field.is_need" style="display: flex; align-items: center;" :prop="field.fieldname" :rules="[{validator: validatefieldname, trigger: 'blur' }]">
    <template v-if="field.type === 'date'">
      <el-date-picker 
        v-model="formData[field.id]" 
        :placeholder="'请输入'+ field.fieldname"
      ></el-date-picker>
    </template>
    <template v-else-if="field.type=='pic'">
      <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequests2"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData[field.id]">
          <template v-for="(it,id) in formData[field.id]">
            <img :src="it" class="avatar2"/>
          </template>
          <img src="/image-2.png" width="100" height="100"/>
        </template>
        <template v-else>
            <img src="/image-1.png" class="avatar2"/>
          </template>
        </el-upload>
    </template>
    <template v-else-if="field.type === 'enum'">
      <el-select v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname">
        <el-option v-for="(option, index2) in field.enums" :key="index2" :label="option.label" :value="option"></el-option>
      </el-select>
    </template>
    <template v-else-if="field.type === 'phone'">
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname" @blur="validatePhone"></el-input>
    </template>
    <template v-else-if="field.type === 'number'">
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname" @blur="validateNumber"></el-input>
    </template>
    <template v-else>
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname"></el-input>
    </template>
  </el-form-item>
</template>
</el-col>
</el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
        </el-form-item>
      </el-form>
    </Layout>
  </template>
  
<script>
import axios from 'axios';
import {baseURL } from '@/api/base.js';
export default {
  beforeMount(){
      axios.post(baseURL + '/api/report/isgetreport', {id:this.id}, {
             headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
             }
           })
           .then((response) => {
            if(response.data.code==503){
              this.$router.push('/')
            }
           })
           .catch((error) => {
             console.error('提交错误:', error);
          });
    },
    props: ['id'],
  data() {
    return {
      rules: {
          phone: [
            { validator: this.validatePhone, trigger: 'blur' }
          ]
        },
        formData:{
          avatar: "/image-1.png",
          realname: "",
          phone: "",
          degree: "",
          is_fulltime: "",
          school: "",
          subject_type: "",
          sex: "",
          birthday: "",
          subject: "",   
      },
        educationOptions: [
        { label: "高中/中专", value: "高中/中专" },
        { label: "专科", value: "专科" },
        { label: "本科", value: "本科" },
        { label: "硕士研究生", value: "硕士研究生" },
        { label: "博士研究生", value: "博士研究生" },
      ],
        majorList: [
            { value: '哲学', label: '哲学' },
            { value: '逻辑学', label: '逻辑学' },
            { value: '宗教学', label: '宗教学' },
            { value: '经济学', label: '经济学' },
            { value: '财政学', label: '财政学' },
            { value: '金融学', label: '金融学' },
            { value: '经济统计学', label: '经济统计学' },
            { value: '投资学', label: '投资学' },
            { value: '保险学', label: '保险学' },
            { value: '国际经济与贸易', label: '国际经济与贸易' },
            { value: '金融工程', label: '金融工程' },
            { value: '税收学', label: '税收学' },
            { value: '法学', label: '法学' },
            { value: '政治学', label: '政治学' },
            { value: '社会学', label: '社会学' },
            { value: '国际政治', label: '国际政治' },
            { value: '外交学', label: '外交学' },
            { value: '社会工作', label: '社会工作' },
            { value: '民族学', label: '民族学' },
            { value: '马克思主义理论', label: '马克思主义理论' },
            { value: '思想政治教育', label: '思想政治教育' },
            { value: '教育学', label: '教育学' },
            { value: '学前教育', label: '学前教育' },
            { value: '特殊教育', label: '特殊教育' },
            { value: '教育技术学', label: '教育技术学' },
            { value: '文学', label: '文学' },
            { value: '汉语言文学', label: '汉语言文学' },
            { value: '汉语言', label: '汉语言' },
            { value: '英语', label: '英语' },
            { value: '俄语', label: '俄语' },
            { value: '日语', label: '日语' },
            { value: '德语', label: '德语' },
            { value: '法语', label: '法语' },
            { value: '西班牙语', label: '西班牙语' },
            { value: '阿拉伯语', label: '阿拉伯语' },
            { value: '新闻学', label: '新闻学' },
            { value: '传播学', label: '传播学' },
            { value: '编辑出版学', label: '编辑出版学' },
            { value: '广告学', label: '广告学' },
            { value: '戏剧影视文学', label: '戏剧影视文学' },
            { value: '历史学', label: '历史学' },
            { value: '世界史', label: '世界史' },
            { value: '考古学', label: '考古学' },
            { value: '文物与博物馆学', label: '文物与博物馆学' },
            { value: '理学', label: '理学' },
            { value: '数学', label: '数学' },
            { value: '物理学', label: '物理学' },
            { value: '化学', label: '化学' },
            { value: '生物科学', label: '生物科学' },
            { value: '天文学', label: '天文学' },
            { value: '地理科学', label: '地理科学' },
            { value: '大气科学', label: '大气科学' },
            { value: '海洋科学', label: '海洋科学' },
            { value: '地球物理学', label: '地球物理学' },
            { value: '地质学', label: '地质学' },
            { value: '心理学', label: '心理学' },
            { value: '统计学', label: '统计学' },
            { value: '工学', label: '工学' },
            { value: '机械工程', label: '机械工程' },
            { value: '车辆工程', label: '车辆工程' },
            { value: '电气工程', label: '电气工程' },
            { value: '电子信息工程', label: '电子信息工程' },
            { value: '通信工程', label: '通信工程' },
            { value: '计算机科学与技术', label: '计算机科学与技术' },
            { value: '软件工程', label: '软件工程' },
            { value: '土木工程', label: '土木工程' },
            { value: '建筑环境与能源应用工程', label: '建筑环境与能源应用工程' },
            { value: '给排水科学与工程', label: '给排水科学与工程' },
            { value: '化学工程与工艺', label: '化学工程与工艺' },
            { value: '制药工程', label: '制药工程' },
            { value: '纺织工程', label: '纺织工程' },
            { value: '服装设计与工程', label: '服装设计与工程' },
            { value: '交通运输工程', label: '交通运输工程' },
            { value: '航海工程', label: '航海工程' },
            { value: '航空航天工程', label: '航空航天工程' },
            { value: '兵器工程', label: '兵器工程' },
            { value: '核工程与核技术', label: '核工程与核技术' },
            { value: '环境工程', label: '环境工程' },
            { value: '安全工程', label: '安全工程' },
            { value: '农学', label: '农学' },
            { value: '园艺学', label: '园艺学' },
            { value: '植物保护', label: '植物保护' },
            { value: '茶学', label: '茶学' },
            { value: '林学', label: '林学' },
            { value: '园林', label: '园林' },
            { value: '森林保护', label: '森林保护' },
            { value: '草学', label: '草学' },
            { value: '动物科学', label: '动物科学' },
            { value: '动物医学', label: '动物医学' },
            { value: '水产养殖学', label: '水产养殖学' },
            { value: '海洋渔业科学与技术', label: '海洋渔业科学与技术' },
            { value: '医学', label: '医学' },
            { value: '临床医学', label: '临床医学' },
            { value: '口腔医学', label: '口腔医学' },
            { value: '中医学', label: '中医学' },
            { value: '针灸推拿学', label: '针灸推拿学' },
            { value: '药学', label: '药学' },
            { value: '中药学', label: '中药学' },
            { value: '法医学', label: '法医学' },
            { value: '医学检验技术', label: '医学检验技术' },
            { value: '医学影像技术', label: '医学影像技术' },
            { value: '康复治疗学', label: '康复治疗学' },
            { value: '预防医学', label: '预防医学' },
            { value: '护理学', label: '护理学' },
            { value: '管理学', label: '管理学' },
            { value: '工商管理', label: '工商管理' },
            { value: '市场营销', label: '市场营销' },
            { value: '会计学', label: '会计学' },
            { value: '财务管理', label: '财务管理' },
            { value: '人力资源管理', label: '人力资源管理' },
            { value: '旅游管理', label: '旅游管理' },
            { value: '公共管理', label: '公共管理' },
            { value: '行政管理', label: '行政管理' },
            { value: '土地资源管理', label: '土地资源管理' },
            { value: '农林经济管理', label: '农林经济管理' },
            { value: '信息管理与信息系统', label: '信息管理与信息系统' },
            { value: '工程管理', label: '工程管理' },
            { value: '物流管理', label: '物流管理' },
            { value: '艺术学', label: '艺术学' },
            { value: '音乐学', label: '音乐学' },
            { value: '舞蹈学', label: '舞蹈学' },
            { value: '美术学', label: '美术学' },
            { value: '绘画', label: '绘画' },
            { value: '雕塑', label: '雕塑' },
            { value: '书法学', label: '书法学' },
            { value: '摄影', label: '摄影' },
            { value: '视觉传达设计', label: '视觉传达设计' },
            { value: '环境设计', label: '环境设计' },
            { value: '产品设计', label: '产品设计' },
            { value: '服装与服饰设计', label: '服装与服饰设计' },
            { value: '公共艺术', label: '公共艺术' },
            { value: '数字媒体艺术', label: '数字媒体艺术' },
            { value: '艺术与科技', label: '艺术与科技' }
          ],
        fieldlist: [
            // 动态字段列表
        ],
        formlist: {},
        uploadUrl:'',
        defaultUrl:'',
        rules: {
        realname: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { required: true, message: '请正确输入用户名，必须2-4位中文', trigger: 'blur',pattern: /^[\u4E00-\u9FA5]{2,4}$/}
      ],

}
    }
  },
  mounted(){
     this.fetchDetail(this.id);
     this.getbnotes()
     this.uploadUrl = baseURL + '/api/bnotes/upload';
     this.defaultUrl='/image-1.png'
  },
   created() {
    // 初始化formData
    this.fieldlist.forEach(field => {
      this.$set(this.formData, field.id, '');
    });
  },
  computed: {
    uploadRelatedValues() {
            const currentFieldIndex = this.fieldlist.findIndex(field => field.type === 'pic' && field.file === this.currentUploadFile);
            const currentFieldId = this.fieldlist[currentFieldIndex].id;
            const currentFieldValue = this.formData[currentFieldId];

            return {
                fieldId: currentFieldId,
                fieldValue: currentFieldValue,
                fieldType: this.fieldlist[currentFieldIndex].type
            };
        }
},
  methods: {
    getbnotes(){
      axios.post(baseURL + '/api/bnotes/status',{},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // 从 localStorage 中获取 token
        }}).then((res)=>{
          if (res.data.status==1) {
            this.formData = res.data.data;
        }else if(res.data.code==503){
          this.$router.push('/')
        }
        })
    },
    validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
      validateidcard2(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      validategrad(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      validateidcard(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      // 
      validateEdu(rule, value, callback){
        if (!value) {
          callback(new Error('请选择教育经历'));
        } 
      },
      validatesubjectType(rule, value, callback){
        if (!value) {
          callback(new Error('请选择专业名称'));
        } 
      },
    validatefieldname(rule,value,callback){
      if (!value) {
          callback(new Error('值不能为空'));
        } 
    },
      validatesubject(rule, value, callback){
        if (!value) {
          callback(new Error('请输入专业'));
        } 
      },
      validateschool(rule, value, callback){
        if (!value) {
          callback(new Error('请输入毕业院校'));
        } 
      },
      validatebirthday(rule, value, callback){
        if (!value) {
          callback(new Error('请选择出生日期'));
        } 
      },
      validateAvatar(rule, value, callback) {
        if (!value) {
          callback(new Error('头像不能为空'));
        }
      },
      validateRealname(rule, value, callback) {
        if (!value) {
          callback(new Error('姓名不能为空'));
        } else if (!/^[\u4E00-\u9FA5]{2,4}$/) {
          callback(new Error('请输入2-4位中文姓名'));
        } else {
          callback();
        }
      },
      validateSex(rule, value, callback) {
        if (!value) {
          callback(new Error('请选择性别'));
        } 
      },
      validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
      validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
    handleSuccess(response) {
        if (response && response.path) {
          
          this.formData.avatar = response.path; // 假设后端返回的响应中包含头像的 URL
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
       // 上传前的验证
       beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      // 上传成功的回调
      handleAvatarSuccess(response) {
        if (response && response.path) {
          this.formData.avatar = response.path; // 假设后端返回的响应中包含头像的 URL
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
    handleclick()
      {
        const inputvalue=this.$refs.uploadRef.$el.querySelector('input[type="file"]')
        if(inputvalue){
            inputvalue.click();
        }
      },
    httpRequests2(option){
        const formData = new FormData();
         // 获取当前正在上传图片对应的formData[field.id]的值（假设fieldlist和formData的对应关系是按顺序匹配的，实际可能需要更精准的获取方式）
         const { fieldId} = this.uploadRelatedValues;
        console.log(fieldId)

        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if(this.formData[fieldId]){
            this.formData[fieldId].push(response.data.path)
            console.log(response.data.path); // 成功回调
          }else{
            this.formData[fieldId]=[response.data.path];
          }
       
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequests(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.avatar=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestidcard(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.idcard=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestidcard2(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.idcard2=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestgrad(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.grad=response.data.path
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
   // 添加一个方法来格式化日期
   formattedDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    fetchDetail(id) {
        var that=this;
        axios
        .post(baseURL+'/api/fieldlist/getfield', {id:id},{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }}) //
        .then((response) => {
          this.fieldlist= response.data; // 假设后端返回的是项目列表
          if(this.fieldlist){
            this.fieldlist.forEach((data)=>{
                that.formlist=data;
                console.log(data)
            })
          }
        })
        .catch((error) => {
          console.error('错误:', error);
        });  
      },
    validateNumber(rule, value) {
      const numberRegex = /^\d+$/;
      if (!value) {
        //this.$message.error('请输入数字')
      } else if (!numberRegex.test(value)) {
        this.$message.error('请输入正确的数字格式')
      }
    },
      submitForm()
      {
          axios.post(baseURL+'/api/report/setreport',{formData:this.formData,eid:this.id},{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }}).then((res)=>{
               if(res.data.code==200){
                  this.$message.success(res.data.msg)
                  this.$router.push('/ApplyInfo')
               }else{
                  this.$message.error(res.data.msg)
               }
          })
  },
  beforeUpload(file) {
                    const isPNGorJPG = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
                    if (!isPNGorJPG) {
                        this.$message.error('请上传PNG或JPG格式的文件');
                        return false;
                    }
                    return true;
                },
                handleSuccess(response, file, fileList) {
                  console.log(response)
                    console.log(fileList)
                    console.log(file)
                    this.$message.success('文件上传成功');
                }

              }
            }
</script>
<style>
.el-form-item__label {
  white-space: nowrap;
}
.avatar-uploader {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  avatar-uploader2{
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    position: relative;
  }
  .avatar2 {
    width: 100px;
    height: 100px;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .submitted-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
  }
.basic-title{
  color:#87CEEB;
}
.avatar-uploader-wrapper {
    border: 1px solid #000; /* 这里设置边框样式，可根据需要调整颜色、宽度等 */
    display: inline-block; /* 保持元素为内联块级元素，以便与其他元素正常排列 */
}
.bottom{
  margin-bottom:5px;
}
.elcol2{
  padding-left: 5rem;
}
</style>