<template>
   <div><span class="upmobile">手机号：{{tel}}<span class="red" @click="updatedit(1)">[修改]</span></span><span class="marginleft">密码:{{password}}</span><span class="red" @click="updatedit(3)">【修改】</span></div>
    <div>
      <el-form v-if="status==0" ref="resumeForm" :model="formData" label-width="120px" style="margin-top: 30px;">
        <el-divider><span class="basic-title">基本信息</span></el-divider>
<el-row>
        <!-- 左边列 -->
  <el-col :span="12">
    <el-form-item class="bottom" label="证件照" :rules="[
          { required: true}]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            :limit="1"
            accept=".png,.jpg,.jpeg"
        >
            <img v-if="formData.avatarUrl" :src="formData.avatarUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        </el-form-item>
    <el-form-item label="真实姓名" :rules="[
          { required: true, message: '真实姓名不能为空', trigger: 'blur' }
        ]">
          <el-input v-model="formData.realname" style="width: 30%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :rules="[
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ]">
          <el-input v-model="formData.phone" style="width: 30%;"></el-input>
        </el-form-item>
        <el-form-item label="学历是否全日制" :required="true" prop="is_fulltime">
        <el-select v-model="formData.is_fulltime" placeholder="请选择是否全日制" style="width: 30%;">
          <el-option value="是">是</el-option>
          <el-option value="否">否</el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="性别" :required="true" prop="sex" :rules="[{validator: validateSex, trigger: 'blur' }]">
        <el-select v-model="formData.sex" placeholder="请选择性别" style="width: 30%;">
          <el-option value="男">男</el-option>
          <el-option value="女">女</el-option>
        </el-select>
    </el-form-item>
        <el-form-item label="学历" :required="true">
      <el-select v-model="formData.degree" placeholder="请选择学历" style="width: 30%;">
            <el-option label="高中/中专" value="高中/中专"></el-option>
            <el-option label="专科" value="专科"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="出生年月" :required="true" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker v-model="formData.birthday" type="date" placeholder="选择日期" style="width: 30%;">
        </el-date-picker>
        </el-form-item>
  </el-col>
  <!-- 右边列 -->
  <el-col :span="12">
    <el-form-item label="毕业学校">
      <el-input v-model="formData.school" placeholder="请输入毕业学校" style="width: 30%;"></el-input>
    </el-form-item>
    <el-form-item label="专业类型" :required="true" prop="subject_type" :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
        <el-select v-model="formData.subject_type" placeholder="请选择专业" style="width: 30%;">
          <el-option
        v-for="major in majorList"
        :key="major.value"
        :label="major.label"
        :value="major.value"
      ></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="专业" :required="true" prop="subject" :rules="[{validator: validatesubject, trigger: 'blur' }]">
      <el-input v-model="formData.subject" style="width: 30%;"></el-input>
    </el-form-item>
  </el-col>
</el-row>
        <el-button class="ebutton" type="primary" @click="submitResume">提交</el-button>
      </el-form>
  <!-- 根据status的值来决定显示哪个部分 -->
  <el-form v-else-if="status==1" style="padding-left:1rem;">
    <h2>我的资料</h2>
      <el-form-item class="bottom" label="证件照" :rules="[
          { required: true}]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            :limit="1"
            accept=".png,.jpg,.jpeg"
            :disabled="isEditing === false">
        <template v-if="submittedData.avatar">
            <img :src="submittedData.avatar" class="avatar" />
          </template>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
    </el-form-item>
    <el-form-item label="姓名">
      <el-input v-model="submittedData.realname" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="电话">
      <el-input v-model="submittedData.phone" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="毕业学校">
      <el-input v-model="submittedData.school" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="学历是否全日制">
        <el-select v-model="submittedData.is_fulltime" :disabled="isEditing==false">
          <el-option label="是" value="是" :selected="submittedData.is_fulltime === 0? 'selected' : false" ></el-option>
          <el-option label="否" value="否" :selected="submittedData.is_fulltime === 1? 'selected' : false" ></el-option>
        </el-select>
</el-form-item>
    <el-form-item label="专业类型" prop="subject_type" :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
        <el-select v-model="submittedData.subject_type" placeholder="请选择专业" :disabled="isEditing === false">
          <el-option
        v-for="major in majorList"
        :key="major.value"
        :label="major.label"
        :value="major.value"
        ></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="出生年月" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker :disabled="isEditing === false" v-model="submittedData.birthday" type="date" placeholder="选择日期" style="width: 30%;">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="学历">
          <el-select v-model="submittedData.degree" placeholder="请选择学历" :disabled="isEditing === false">
    <!-- 高中/中专选项 -->
    <el-option label="高中/中专" value="高中/中专" :selected="submittedData.degree === '高中/中专'? 'selected' : false" :class="{ 'selected': submittedData.degree === '高中/中专' }"></el-option>
    <!-- 专科选项 -->
    <el-option label="专科" value="专科" :selected="submittedData.degree === '专科'? 'selected' : false" :class="{ 'selected': submittedData.degree === '专科' }"></el-option>
    <!-- 本科选项 -->
    <el-option label="本科" value="本科" :selected="submittedData.degree === '本科'? 'selected' : false" :class="{ 'selected': submittedData.degree === '本科' }"></el-option>
    <!-- 硕士选项 -->
    <el-option label="硕士" value="硕士" :selected="submittedData.degree === '硕士'? 'selected' : false" :class="{ 'selected': submittedData.degree === '硕士' }"></el-option>
    <!-- 硕士选项 -->
    <el-option label="博士" value="博士" :selected="submittedData.degree === '博士'? 'selected' : false" :class="{ 'selected': submittedData.degree === '博士' }"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
        <el-select v-model="submittedData.sex" placeholder="请选择性别" :disabled="isEditing === false">
            <el-option label="男" value="男" :selected="submittedData.sex === 0? 'selected' : false" ></el-option>
            <el-option label="女" value="女" :selected="submittedData.sex === 1? 'selected' : false" ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="专业">
        <el-input v-model="submittedData.subject" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <template v-if="isEditing === false">
      <el-button @click="changeToEditMode(1)">修改</el-button>
    </template>
    <template v-else>
      <el-button @click="changeToEditMode(2)">不修改</el-button>
    <el-button  @click="submitForm">提交</el-button>
  </template>
      </el-form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { baseURL } from '@/api/base.js';
  import { ElMessage } from 'element-plus';
  export default {
    data() {
      return {
        status: 0, // 标记是否已经提交过
        formData: {
          avatarUrl: '/image-1.png',
          realname:'',
          phone:'',
          degree:'',
          is_fulltime:'',
          school:'',
          sex:'',
          birthday:'',
          subject_type:'',
          subject:''
        },
        additionalInputs: [],
        formErrors: [],  // 错误提示
        initialInput: '',
        uploadUrl:'',
        submittedData: {},
        isEditing: false,
        tel:'',
        idcard:'',
        password:'',
        majorList: [
            { value: '哲学', label: '哲学' },
            { value: '逻辑学', label: '逻辑学' },
            { value: '宗教学', label: '宗教学' },
            { value: '经济学', label: '经济学' },
            { value: '财政学', label: '财政学' },
            { value: '金融学', label: '金融学' },
            { value: '经济统计学', label: '经济统计学' },
            { value: '投资学', label: '投资学' },
            { value: '保险学', label: '保险学' },
            { value: '国际经济与贸易', label: '国际经济与贸易' },
            { value: '金融工程', label: '金融工程' },
            { value: '税收学', label: '税收学' },
            { value: '法学', label: '法学' },
            { value: '政治学', label: '政治学' },
            { value: '社会学', label: '社会学' },
            { value: '国际政治', label: '国际政治' },
            { value: '外交学', label: '外交学' },
            { value: '社会工作', label: '社会工作' },
            { value: '民族学', label: '民族学' },
            { value: '马克思主义理论', label: '马克思主义理论' },
            { value: '思想政治教育', label: '思想政治教育' },
            { value: '教育学', label: '教育学' },
            { value: '学前教育', label: '学前教育' },
            { value: '特殊教育', label: '特殊教育' },
            { value: '教育技术学', label: '教育技术学' },
            { value: '文学', label: '文学' },
            { value: '汉语言文学', label: '汉语言文学' },
            { value: '汉语言', label: '汉语言' },
            { value: '英语', label: '英语' },
            { value: '俄语', label: '俄语' },
            { value: '日语', label: '日语' },
            { value: '德语', label: '德语' },
            { value: '法语', label: '法语' },
            { value: '西班牙语', label: '西班牙语' },
            { value: '阿拉伯语', label: '阿拉伯语' },
            { value: '新闻学', label: '新闻学' },
            { value: '传播学', label: '传播学' },
            { value: '编辑出版学', label: '编辑出版学' },
            { value: '广告学', label: '广告学' },
            { value: '戏剧影视文学', label: '戏剧影视文学' },
            { value: '历史学', label: '历史学' },
            { value: '世界史', label: '世界史' },
            { value: '考古学', label: '考古学' },
            { value: '文物与博物馆学', label: '文物与博物馆学' },
            { value: '理学', label: '理学' },
            { value: '数学', label: '数学' },
            { value: '物理学', label: '物理学' },
            { value: '化学', label: '化学' },
            { value: '生物科学', label: '生物科学' },
            { value: '天文学', label: '天文学' },
            { value: '地理科学', label: '地理科学' },
            { value: '大气科学', label: '大气科学' },
            { value: '海洋科学', label: '海洋科学' },
            { value: '地球物理学', label: '地球物理学' },
            { value: '地质学', label: '地质学' },
            { value: '心理学', label: '心理学' },
            { value: '统计学', label: '统计学' },
            { value: '工学', label: '工学' },
            { value: '机械工程', label: '机械工程' },
            { value: '车辆工程', label: '车辆工程' },
            { value: '电气工程', label: '电气工程' },
            { value: '电子信息工程', label: '电子信息工程' },
            { value: '通信工程', label: '通信工程' },
            { value: '计算机科学与技术', label: '计算机科学与技术' },
            { value: '软件工程', label: '软件工程' },
            { value: '土木工程', label: '土木工程' },
            { value: '建筑环境与能源应用工程', label: '建筑环境与能源应用工程' },
            { value: '给排水科学与工程', label: '给排水科学与工程' },
            { value: '化学工程与工艺', label: '化学工程与工艺' },
            { value: '制药工程', label: '制药工程' },
            { value: '纺织工程', label: '纺织工程' },
            { value: '服装设计与工程', label: '服装设计与工程' },
            { value: '交通运输工程', label: '交通运输工程' },
            { value: '航海工程', label: '航海工程' },
            { value: '航空航天工程', label: '航空航天工程' },
            { value: '兵器工程', label: '兵器工程' },
            { value: '核工程与核技术', label: '核工程与核技术' },
            { value: '环境工程', label: '环境工程' },
            { value: '安全工程', label: '安全工程' },
            { value: '农学', label: '农学' },
            { value: '园艺学', label: '园艺学' },
            { value: '植物保护', label: '植物保护' },
            { value: '茶学', label: '茶学' },
            { value: '林学', label: '林学' },
            { value: '园林', label: '园林' },
            { value: '森林保护', label: '森林保护' },
            { value: '草学', label: '草学' },
            { value: '动物科学', label: '动物科学' },
            { value: '动物医学', label: '动物医学' },
            { value: '水产养殖学', label: '水产养殖学' },
            { value: '海洋渔业科学与技术', label: '海洋渔业科学与技术' },
            { value: '医学', label: '医学' },
            { value: '临床医学', label: '临床医学' },
            { value: '口腔医学', label: '口腔医学' },
            { value: '中医学', label: '中医学' },
            { value: '针灸推拿学', label: '针灸推拿学' },
            { value: '药学', label: '药学' },
            { value: '中药学', label: '中药学' },
            { value: '法医学', label: '法医学' },
            { value: '医学检验技术', label: '医学检验技术' },
            { value: '医学影像技术', label: '医学影像技术' },
            { value: '康复治疗学', label: '康复治疗学' },
            { value: '预防医学', label: '预防医学' },
            { value: '护理学', label: '护理学' },
            { value: '管理学', label: '管理学' },
            { value: '工商管理', label: '工商管理' },
            { value: '市场营销', label: '市场营销' },
            { value: '会计学', label: '会计学' },
            { value: '财务管理', label: '财务管理' },
            { value: '人力资源管理', label: '人力资源管理' },
            { value: '旅游管理', label: '旅游管理' },
            { value: '公共管理', label: '公共管理' },
            { value: '行政管理', label: '行政管理' },
            { value: '土地资源管理', label: '土地资源管理' },
            { value: '农林经济管理', label: '农林经济管理' },
            { value: '信息管理与信息系统', label: '信息管理与信息系统' },
            { value: '工程管理', label: '工程管理' },
            { value: '物流管理', label: '物流管理' },
            { value: '艺术学', label: '艺术学' },
            { value: '音乐学', label: '音乐学' },
            { value: '舞蹈学', label: '舞蹈学' },
            { value: '美术学', label: '美术学' },
            { value: '绘画', label: '绘画' },
            { value: '雕塑', label: '雕塑' },
            { value: '书法学', label: '书法学' },
            { value: '摄影', label: '摄影' },
            { value: '视觉传达设计', label: '视觉传达设计' },
            { value: '环境设计', label: '环境设计' },
            { value: '产品设计', label: '产品设计' },
            { value: '服装与服饰设计', label: '服装与服饰设计' },
            { value: '公共艺术', label: '公共艺术' },
            { value: '数字媒体艺术', label: '数字媒体艺术' },
            { value: '艺术与科技', label: '艺术与科技' }
          ],
      };
    },
    methods: {
      changeToEditMode(type) {
        if(type==1){
          this.isEditing = true;
        }else{
          this.isEditing = false;
        }

    },
    validatebirthday(rule, value, callback){
        if (!value) {
          callback(new Error('请选择出生年月'));
        } 
      },
      validatesubjectType(rule, value, callback){
        if (!value) {
          callback(new Error('请选择专业名称'));
        } 
      },
    getuser(){
      var that=this;
      // 这里添加提交表单的具体逻辑，比如发送数据到服务器等
      console.log('提交表单，数据为：', this.submittedData);
      axios.post(baseURL+'/api/bnotes/getuser', {},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.code==503){
               window.location.reload()
              }
              if(response.data.code==200){
                 this.tel=response.data.data.tel;
                 this.idcard=response.data.data.idcard;
                 this.password=response.data.data.password;
              }else{
                 this.$message.error(response.data.msg)
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
    },
    updatedit(tab){
      if(tab==1){
        this.$router.push('UpdatePassword/'+1)
      }else if(tab==2){
        this.$router.push('/UpdateIdCard')
      }else if(tab==3){
        this.$router.push('UpdatePassword/'+3)
      }
    },
    updates(){
      this.$router.push('/UpdatePassword')
    },
    submitForm() {
      var that=this;
      // 这里添加提交表单的具体逻辑，比如发送数据到服务器
      axios.post(baseURL+'/api/bnotes/setbnote', {submitdata:that.submittedData},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.code==503){
               window.location.reload()
              }
              if(response.data.code==200){
                window.location.reload()
              }else{
                 this.$message.error(response.data.msg)
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
      // 提交后可根据需求重置状态等，示例如下：
      this.isEditing = false;
    },
      addInput() {
    this.additionalInputs.push({ value: '' });
      },
      removeInput(index) {
        this.additionalInputs.splice(index, 1);
      },
      httpRequests(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          option.onSuccess(response.data); // 成功回调
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      async fetchSubmittedResume() {
        axios.post(baseURL + '/api/bnotes/status',{},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // 从 localStorage 中获取 token
        }}).then((res)=>{
          if (res.data.status==1) {
            this.status = 1;
            this.submittedData = res.data.data;
        }else if(res.data.code==503){
          this.$router.push('/')
        }
        })
},
      // 上传前的验证
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      validatesubject(rule, value, callback){
        if (!value) {
          callback(new Error('请输入专业名称'));
        } 
      },
      // 上传成功的回调
      handleAvatarSuccess(response) {
        if (response && response.path) {
          this.formData.avatarUrl = response.path; // 假设后端返回的响应中包含头像的 URL
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
      async submitResume() {
        let arr=[]
        arr.push(this.formData.exp);
        let extraarr= this.additionalInputs
        extraarr.forEach(item => {
            arr.push(item.value)
        });
        console.log(this.formData)
  try {

    const response = await axios.post(baseURL + '/api/bnotes/submit', this.formData,{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }});
    if (response.data.status==1) {
      this.$message.success('资料提交成功!');
      this.submittedData = this.formData;
      window.location.reload();
    } else {
        ElMessage.error(response.data.msg);
    }
  } catch (error) {
    ElMessage.error('网络错误，请稍后再试!');
    console.error(error)
  }
},
      handleclick()
      {
        const inputvalue=this.$refs.uploadRef.$el.querySelector('input[type="file"]')
        if(inputvalue){
            inputvalue.click();
        }
      }
    },
    created() {
      // 页面加载时检查是否已经提交过资料
      this.fetchSubmittedResume();
    },
    mounted(){
      this.uploadUrl = baseURL + '/api/bnotes/upload';
      this.getuser()
      if(this.submittedData){
        const subjectTypeValue = this.submittedData.subject_type;
  // 遍历majorList找到匹配的选项并设置选中
  this.majorList.forEach(major => {
    if (major.value === subjectTypeValue) {
      this.submittedData.subject_type = major.value; // 这样就能触发v-model双向绑定，选中对应的选项
    }
  });
      }
    }
  };
  </script>
  
  <style scoped>
  .ebutton {
    width: 200px;
    margin-left:35%;
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .submitted-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
  }
  .custom-breadcrumb {
    display: flex;
}
.basic-title{
  color:#87CEEB;
}
.avatar-uploader-wrapper {
    border: 1px solid #000; /* 这里设置边框样式，可根据需要调整颜色、宽度等 */
    display: inline-block; /* 保持元素为内联块级元素，以便与其他元素正常排列 */
}
.center{
  text-align: left;
}
.red{
  color:red;
  cursor:pointer;
}
.marginleft{
  margin-left:10%;
}
.upmobile{
  padding-left:3%;
}
  </style>
  