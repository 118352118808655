<template>
    <Layout>
      <div class="tableData1" :style="{'overflow-x':'hidden', 'overflow-y':'hidden'}">
    <div class="ttitle">系统公告<span><el-link class="ell" href="/MainGongao">查看更多>></el-link></span></div>
    <div v-if="tableData1 && tableData1.length > 0" class="tableData2">
        <el-table :data="tableData1" @row-click="handleRowClick">
            <el-table-column
                    prop="create_time"
                    label="日期"
                    > <!-- 适当调小列宽 -->
            </el-table-column>
            <el-table-column
                    prop="title"
                    label="标题"
                    > <!-- 适当调小列王 -->
            </el-table-column>
            <el-table-column prop="content" label="内容">
                <template v-slot="scope">
                    <div class="scopecontent">{{ scope.row.content?cleanContent(scope.row.content):'' }}</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <el-empty v-else description="暂无新消息"></el-empty>
    <el-dialog v-model="dialogVisible" title="详情" width="30%">
      <div v-html="dialogContent"></div>
      <template v-slot:footer>
        <el-button @click="getread(this.dialogId)">取消</el-button>
        <el-button type="primary" @click="getread(this.dialogId)">确定</el-button>
      </template>
    </el-dialog>
</div>
<div class="tableData3" :style="{ 'overflow-x':'hidden', 'overflow-y':'hidden' }">
    <div class="ttitle">我的消息<span><el-link class="ell" href="/messages">阅读>></el-link></span></div>
    <div v-if="tableData2 && tableData2.length > 0" class="tableData2">
        <el-table :data="tableData2">
            <el-table-column
                    prop="createtime"
                    label="日期"
                    > <!-- 适当调小列宽 -->
            </el-table-column>
            <el-table-column
                    prop="subject"
                    label="标题"
                    > <!-- 适当调小列王 -->
            </el-table-column>
            <el-table-column prop="message" label="内容">
            </el-table-column>
        </el-table>
    </div>
    <el-empty v-else description="暂无新消息"></el-empty>
</div>
    </Layout>
  </template>
  <style scoped>
  .float-right{
    float: right;
  }
</style>
  <script>
    import axios from 'axios';
  import {baseURL} from '@/api/base.js';
  export default {
    data() {
      return {
        tableData1:'',
        tableData2:'',
        dialogId:0,
        dialogVisible:false,
        dialogContent:''
      }
    },
    mounted(){
      this.gettabledata()
    },
    methods:{
      gettabledata(){
        var that=this;
        axios
            .post(baseURL+'/api/message/firstpage', {pageSize:this.pageSize,currentPage:this.currentPage},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              that.tableData1= response.data.tableData1;
              that.tableData2=response.data.tableData2;
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
      },
      cleanContent(content){
        let contents=content.replace(/<[^>]+>/g, '');
        if(contents.length>10){
          return contents.substring(0,10)+'...'
        }else{
          return contents
        }
      },
      handleRowClick(row){
          this.showDialog(row)
      },
      showDialog(row){
      const {content,id}=row
      this.dialogContent= content;
      this.dialogVisible= true;
      this.dialogId=id;
    }, 
    getread(id){
      console.log(id)
      axios.post(baseURL + '/api/notice/setread', {
        id: id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then((response)=>{
        if(response.data.msg){
          this.dialogVisible = false;
          window.location.reload()
        }
      })
  }
}
  }
  </script>
  <style>
.box-card2 {
  margin-top: 30px;
}
.tableData1{
  display: inline-block;
  width:600px;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  height: 500px;
  margin-left:5rem;
}
.ttitle{
  height: 30px;
  color:#87CEEB;
  position:absolute;
  top:15px;
  left: 30px;
  display: inline-block;
}
.ell{
  position: relative;
  left:400px;
}
.scopecontent{
  display: flex;
  flex-wrap: wrap;
}
.tableData2{
  margin-top: 50px;
  margin-left:0px;
  display: inline-block;
  width:600px;
  border: 0px solid #dcdcdc;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  height: 500px;
}
.tableData3{
  margin-top: 50px;
  margin-left:3rem;
  display: inline-block;
  width:600px;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  height: 500px;
}
</style>
  